<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t("message.change_write_off") }}</span>
      <el-button
        type="success"
        size="small"
        class="mr-1"
        :loading="loadingButton"
        :disabled="loadingButton"
        @click="submit()"
        >{{ $t("message.refresh") }}</el-button
      >
      <el-button
        type="warning"
        icon="el-icon-close"
        size="small"
        @click="resetForm('form')"
        >{{ $t("message.close") }}</el-button
      >
    </header>

    <div class="body__modal">
      <el-form
        ref="form"
        :model="form"
        size="small"
        class="aticler_m stylekhanForm"
      >
        <el-card class="box-card mb-4">
          <el-row :gutter="20">
            <el-col :sm="12">
              <div class="d-flex">
                <div class="text_a mr-3">
                  {{ $t("message.write_of_from") }} *
                </div>
                <el-form-item>
                  <el-date-picker
                    v-model="form.date"
                    type="datetime"
                    :placeholder="$t('message.delivery_time')"
                    :format="'dd.MM.yyyy HH:mm'"
                    :value-format="'dd.MM.yyyy HH:mm'"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="40">
            <el-col :sm="12">
              <el-form-item :label="$t('message.warehouse')">
                <el-row :gutter="5">
                  <el-col :span="22">
                    <el-form-item class="mb-0">
                      <warehouses
                        v-model="form.warehouse_id"
                        :warehouse_id="form.warehouse_id"
                      ></warehouses>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :sm="12">
              <el-form-item :label="$t('message.user')">
                <el-row :gutter="5">
                  <el-col :span="22">
                    <el-form-item class="mb-0">
                      <users
                        v-model="form.receiver_id"
                        :user_id="form.receiver_id"
                      ></users>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <el-card class="box-card mb-4">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-table
                border
                :data="items"
                v-loading="loadingItems"
                :row-class-name="tableRowClassName"
              >
                <el-table-column label="#" width="30">
                  <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.name')">
                  <template slot-scope="scope">{{
                    scope.row.product.name
                  }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.available')">
                  <template slot-scope="scope">{{
                    scope.row.available
                  }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.box_available')">
                  <template slot-scope="scope">{{
                    scope.row.box_available
                  }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.quantity')">
                  <template slot-scope="scope">
                    <el-input
                      v-if="scope.row.is_new"
                      v-model="scope.row.quantity"
                    ></el-input>
                    <span v-else>{{ scope.row.quantity }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.box_quantity')">
                  <template slot-scope="scope">
                    <el-input
                      v-if="scope.row.is_new"
                      v-model="scope.row.box_quantity"
                    ></el-input>
                    <span v-else>{{ scope.row.box_quantity }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.actions')">
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.is_new"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="removeWriteOffItem(scope.row)"
                    ></el-button>
                    <el-button
                      v-if="!scope.row.is_new"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="destroyWriteOffItem(scope.row)"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
            <el-col :span="24" class="mt-3">
              <el-form-item class="mb-0">
                <products @append="append"></products>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawerChild from "@/utils/mixins/drawer-child";
import drawer from "@/utils/mixins/drawer";
import warehouses from "@/components/inventory-select/warehouses";
import products from "@/components/selects/products";
import users from "@/components/inventory-select/users";
import form from "@/utils/mixins/form";

export default {
  mixins: [drawerChild, drawer, form],
  components: {
    warehouses,
    users,
    products,
  },
  props: ["writeOff"],
  data() {
    return {
      loadingItems: false,
      old_items: [],
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      model: "writeOffs/model",
      rules: "writeOffs/rules",
      columns: "writeOffs/columns",
    }),
  },
  methods: {
    ...mapActions({
      showProductRemainder: "remainders/show",
      showWriteOff: "writeOffs/show",
      updateWriteOff: "writeOffs/update",
      destroyItem: "writeOffs/destroyItem",
    }),
    afterClosed() {
      this.items = [];
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.close();
    },
    append(product) {
      const item = this.items.find((item) => item.product_id == product.id);
      this.loadingItems = true;
      this.showProductRemainder(product.id)
        .then((response) => {
          const product = response.data.product;
          if (!item) {
            this.items.push({
              is_new: true,
              product_id: product.id,
              available: product.available ? product.available : 0,
              box_available: product.available ? product.available : 0,
              quantity: 1,
              box_quantity: 1,
              product: {
                name: product.name,
              },
            });
          } else {
            item.quantity += 1;
          }
          this.loadingItems = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    afterOpened() {
      this.load();
    },
    load() {
      this.loadingItems = true;
      this.showWriteOff(this.writeOff.id)
        .then((response) => {
          if (response.data.write_off.items) {
            this.items.push(
              ...response.data.write_off.items.map((item) => ({
                id: item.id,
                is_new: false,
                product_id: item.product_id,
                available: item.product.available ? item.product.available : 0,
                quantity: item.quantity,
                product: {
                  name: item.product.name,
                },
              }))
            );
            this.loadingItems = false;
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    submit() {
      const payload = {
        id: this.form.id,
        warehouse_id: this.form.warehouse_id,
        date: this.form.date.toString(),
        receiver_id: this.form.receiver_id,
        new_items: this.items.filter((item) => item.is_new),
      };

      this.updateWriteOff(payload)
        .then((response) => {
          this.$alert(response);
          this.close();
        })
        .catch();
    },
    removeWriteOffItem(item) {
      const index = this.items.indexOf(item);
      this.items.splice(index, 1);
    },
    destroyWriteOffItem(item) {
      let id = item.id;
      this.destroyItem(id)
        .then(() => {
          this.items.splice(this.items.indexOf(item), 1);
        })
        .catch((error) => {
          this.$alert(error);
        });
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.available == 0) {
        return "warning-row";
      }
    },
  },
};
</script>

<style>
.el-table tr.warning-row {
  background-color: #e6a23c;
}
</style>
